import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/admin/purple-docs/templates'

export default {
  listTemplates(filters) {
    const queryString = HelperService.buildSearchQueryString(filters)
    return axios.get(`${baseUrl}${queryString}`)
  },
  getTemplate(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
  createTemplate(template) {
    return axios.post(`${baseUrl}`, template)
  },
  updateTemplate(id, template) {
    return axios.put(`${baseUrl}/${id}`, template)
  },
  deleteTemplate(id) {
    return axios.delete(`${baseUrl}/${id}`)
  }
}
