<template>
  <div class="template-page">
    <section>
      <div class="row">
        <!-- Details -->
        <div class="col-12">
          <b-card>
            <section>
              <h4 class="mb-1">Template Details</h4>
              <div class="row mt-2">
                <div class="col-md-6">
                  <table class="table table-striped">
                    <tbody>
                      <tr><td class="pr-1">Title</td><td>{{ template.title }}</td></tr>
                      <tr><td class="pr-1">Status</td><td>{{ template.is_published | isPublished }}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped">
                    <tbody>
                      <tr><td class="pr-1">Category</td><td>{{ template.template_category.name }}</td></tr>
                      <tr><td class="pr-1">Enterprise</td><td>{{ template.enterprise_uid ? template.enterprise_uid : "All Enterprises" }}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </b-card>
        </div>

        <!-- Content -->
        <div class="col-9">
          <b-card>
            <section>
              <ck-editor v-model="template.content" />
            </section>
          </b-card>
        </div>

        <!-- Actions -->
        <div class="col-3">
          <b-card>
            <p class="font-weight-bolder">Actions</p>
            <b-btn variant="primary"
                   class="full-width mb-1"
                   @click="updateTemplate"
            >Save
            </b-btn>
            <b-btn :variant="template.is_published ? 'warning' : 'success'"
                   class="full-width mb-1"
                   @click="handleToggleStatus"
            >{{ template.is_published ? 'Make Draft' : 'Publish' }}
            </b-btn>
          </b-card>
        </div>
      </div>
    </section>
  </div>

</template>
<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import CkEditor from "@/views/purpleDocs/admin/CKEditor.vue";
import helperService from "@/services/HelperService";
import PurpleDocsTemplateService from "@/services/PurpleDocsTemplateService";
import purpleDocsTemplateService from "@/services/PurpleDocsTemplateService";

export default {
  name: 'PurpleDocTemplate',
  components: { CkEditor },
  data() {
    return {
      template: {
        id: 0,
        title: '',
        content: '',
        enterprise_uid: '',
        template_category_id: 0,
        is_published: false,
        template_category: {
          name: '',
        }
      },
    };
  },
  mounted() {
    this.getTemplate()
  },
  methods: {
    async getTemplate() {
      try {
        const { data } = await PurpleDocsTemplateService.getTemplate(this.$route.params.id)
        this.template = data
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, "An error occurred fetching the document, please refresh and try again.")
      }
    },
    async updateTemplate() {
      try {
        await purpleDocsTemplateService.updateTemplate(this.template.id, this.template)
        helperService.showNotfySuccess(this.$toast, 'The template has been successfully saved.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while saving the template. Please try again.')
      }
    },
    async handleToggleStatus() {
      this.template.is_published = !this.template.is_published
      await this.updateTemplate()
    }
  }
};
</script>
